import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SubscribedPage = () => (
  <Layout>
    <SEO title="Subscribed" />
    <div className="page-headline">
      <div className="container text-center">
        <div className="section-heading mb-0">
          <h1>Subscribed!</h1>
        </div>
        <div className="pb-2 my-3">
          <p>Thank you for subscribing to our newsletter.</p>
          <Link to="/" className="btn btn-secondary">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default SubscribedPage
